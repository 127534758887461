<template>
  <NuxtLink
      v-if="showComponent"
      :key="fullname + weapon"
      :to="getWeaponLink(weapon || '')"
      class="category-link"
  >
    <img :alt="(fullname || '') + ' category item'"
         :src="getImageFromWaxpeer( fullname || '', '120:120')"
         class="link-icon"
         height="50"
         loading="lazy"
         width="50"
    />
    <span class="link-name">{{ $t('filters.weapon.' + weapon) }}</span>
    <span class="link-border"></span>
  </NuxtLink>
</template>

<script lang="ts" setup>
const props = defineProps<{ fullname: string, weapon: string }>()

const {showComponent} = await useDelayShow()
</script>

<style lang="scss" scoped>
@use 'sass:color';

.category-link {
  display: inline-flex;
  align-items: center;
  padding: 4px 16px;
  width: 100%;
  gap: 16px;
  border-radius: 0 8px 8px 0;

  &:hover, &.router-link-exact-active {
    background: color-mix(IN srgb, var(--surfaces-container) 95%, white);

    .link-name {
      color: var(--accent-secondary);
    }
  }

  .link-icon {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    background: radial-gradient(50% 50% at 50% 50%, color-mix(in srgb, var(--accent-on-surface) 40%, transparent) 0%, transparent 100%);
  }

  .link-name {
    color: var(--surfaces-on-surface-variable);
  }
}
</style>
